/* Start - Theme settings */

.IronPop-design .theme-settings-form .Polaris-Box {
  padding: unset;
}

.IronPop-design .theme-settings {
  display: flex;
  flex-wrap: wrap;
}

.IronPop-design .theme-settings .Polaris-BlockStack {
  flex: 1;
}

.IronPop-design .theme-settings > .Polaris-BlockStack {
  padding: 24px 48px;
}

.IronPop-design .theme-settings > .Polaris-BlockStack:first-child {
  border-right: 1px solid #e1e3e5;
  border-bottom: 1px solid #e1e3e5;
  min-width: 300px;
}

/* End - Theme settings */

/* Start - Festive Themes */
.IronPop-design .festive-themes .Polaris-Box {
  padding: 24px 48px;
}

.IronPop-design .festive-themes {
  margin-bottom: 24px;
}

.IronPop-design .festive-themes .actions {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: -12px;
}

.IronPop-design .festive-themes .actions button {
  min-height: 36px;
}

.IronPop-design .festive-themes .Polaris-Box {
  display: grid;
  gap: 24px;
}

.IronPop-design .festive-themes .Polaris-Link {
  color: rgb(105, 107, 125);
}

.IronPop-design .festive-themes .actions > div {
  border-radius: 8px;
  border: 1px solid rgba(215, 215, 215, 1);
  padding: 8px 16px;
}

/* End - Festive Themes */
