.IronPop-home {
  display: grid;
  gap: 28px;
  margin-bottom: 32px;
}

.IronPop-home > div > .Polaris-Box {
  padding: 24px 32px;
  border: unset;
}

.Polaris-Modal-Dialog__Modal .Polaris-Box {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.IronPop-EmbeddedInlineBanner {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  width: 100%;
  background-color: #fcf0ed;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 20;
}

.IronPop-EmbeddedInlineBanner button {
  border: 1px solid;
  margin-left: 6px;
}
