body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 100%;
}

.circle-success {
  background-color: #63a103;
}

.circle-critical {
  background-color: #d9001b;
}

.radio-button-group {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  max-width: 100%; /* Ensure it uses the full width available */
}

.radio-button-wrapper {
  flex: 0 0 33.33%; /* Take up one-third of the width (3 columns) */
  box-sizing: border-box; /* Include padding and border in width calculation */
  padding: 8px; /* Optional: space around each button */
  margin-bottom: 16px;
}

.Polaris-Page > div:last-child {
  margin-bottom: 36px;
}

.Polaris-Box {
  border-radius: 10px;
}

.Polaris-Tabs__Outer {
  display: grid;
  gap: 24px;
}