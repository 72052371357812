.IronPop-SelectProductModal .product-list ul {
  list-style: none;
  display: grid;
  gap: 20px;
  padding: 20px 0;
}
.IronPop-SelectProductModal .product-list ul li {
  display: flex;
  gap: 12px;
  align-items: center;
}