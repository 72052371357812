/* Start - App status */
.IronPop-home .app-status {
  display: flex;
  gap: 36px;
}

.IronPop-home .app-status .image,
.IronPop-home .app-status .image img {
  max-width: 170px;
}

.IronPop-home .app-status .Polaris-Button .Polaris-Text--root {
  display: flex;
  gap: 4px;
}
/* End - App status */

/* Start - Support */
.IronPop-home .support-item {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
}
/* End - Support */

/* Start - Setup guide section */
.IronPop-home .setup-guide-list .item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.IronPop-home .setup-guide-list .item:not(:last-child) {
  border-bottom: 1px solid #e1e3e5;
}

.IronPop-home .setup-guide-list .item .heading {
  display: flex;
  gap: 20px;
  padding: 16px 8px;
}

.IronPop-home .setup-guide-list .item .content {
  padding: 0 32px;
  padding-bottom: 12px;
}
/* End - Setup guide section */

/* Welcome modal */
.Polaris-Modal-Dialog__Modal:has(.IronPop-guideSetupModal),
.Polaris-Modal-Dialog__Modal:has(.IronPop-welcomeModal) {
  max-width: 45.75rem;
}
.IronPop-welcomeModal {
  /* background-color: #ffe7b5bd; */
  padding: 48px 70px 32px;
}

.IronPop-welcomeModal {
  display: flex;
  flex-direction: column;
  gap: 56px;
  align-items: center;
}

.IronPop-welcomeModal > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.IronPop-welcomeModal > div:first-child h1 {
  font-weight: bold;
  font-size: xx-large;
}
.IronPop-welcomeModal > div > p {
  font-size: 16px;
  line-height: 24px;
}

.IronPop-welcomeModal img {
  max-width: 470px;
  width: 100%;
  height: auto;
}

.IronPop-welcomeModal > div button {
  width: 140px;
  height: 30px;
}

.IronPop-guideSetupModal {
  display: flex;
  gap: 20px;
  padding: 4px 24px;
}
.IronPop-guideSetupModal > div:first-child {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.IronPop-guideSetupModal > div:first-child > div {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.IronPop-guideSetupModal > div:last-child {
  width: 30%;
}

.IronPop-guideSetupModal > div:last-child img {
  max-width: 206px;
  height: auto;
}
