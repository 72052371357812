.IronPop-orderCount .Polaris-BlockStack {
  flex: 1;
}

.IronPop-orderCount .content-settings .Polaris-TextField {
  height: 58px;
}

.IronPop-orderCount .settings {
  display: flex;
  flex-wrap: wrap;
}

.IronPop-orderCount .settings > .Polaris-BlockStack {
  padding: 24px 48px;
}

.IronPop-orderCount .settings > .Polaris-BlockStack .Polaris-Layout {
  max-width: 300px;
  margin-top: unset;
}

.IronPop-orderCount .settings > .Polaris-BlockStack:first-child {
  border-right: 1px solid #e1e3e5;
  border-bottom: 1px solid #e1e3e5;
  min-width: 300px;
}

.IronPop-orderCount .properties .Polaris-Select,
.IronPop-orderCount .properties .Polaris-TextField {
  max-width: 260px;
}
