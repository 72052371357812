/* Start - Power User */
.IronPop-settings .power-user {
  margin-bottom: 24px;
}

.IronPop-settings .power-user .actions {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: -12px;
}

.IronPop-settings .power-user .actions button {
  min-height: 36px;
}

.IronPop-settings .power-user .Polaris-Box {
  display: grid;
  gap: 24px;
}

.IronPop-settings .power-user .Polaris-Link {
  color: rgb(105, 107, 125);
}

.IronPop-settings .power-user .actions > div {
  border-radius: 8px;
  border: 1px solid rgba(215, 215, 215, 1);
  padding: 8px 16px;
}
/* End - Power User */

/* Start - General Settings */
.IronPop-settings .properties .Polaris-Select,
.IronPop-settings .properties .Polaris-TextField {
  max-width: 300px;
  min-width: 200px;
}

.IronPop-settings .properties .row {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}
.IronPop-settings .properties .row > div {
  flex: 1 45%;
}

.IronPop-settings #divider {
  padding: 20px 0;
}

/* End - General Settings */
