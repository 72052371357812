.IronPop-salesNoti .Polaris-Box {
  padding: unset;
}

.IronPop-salesNoti .sale-noti-settings .Polaris-Box {
  padding: 24px 48px;
}

.IronPop-salesNoti .Polaris-Tabs__Wrapper {
  border-bottom: 1px solid #e1e3e5;
}

.IronPop-salesNoti .Polaris-Tabs__Wrapper .Polaris-Tabs__Tab {
  border-radius: unset;
  border-top-left-radius: var(--p-border-radius-200);
  border-top-right-radius: var(--p-border-radius-200);
}
