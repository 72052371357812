/* Start - Toggle */
.ip-toggle {
  display: flex;
  width: fit-content;
}

.ip-toggle-label--prefix {
  margin-right: 10px;
}

.ip-toggle-label--suffix {
  margin-left: 10px;
}

.ip-toggle-label--disabled {
  color: #b2bbc8;
}

.ip-toggle-bg {
  width: 32px;
  height: 20px;
  border-radius: 12px;
  border: none;
  padding: 2px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background: #e3e3e3;
  transition: background-color 0.2s ease;
}

.ip-toggle-bg--disabled {
  background: #f3f3f3;
  cursor: default;
}

.ip-toggle-bg--checked {
  background: black;
}

.ip-toggle-bg--checked-disabled {
  background: #a7b7f4;
  cursor: default;
}

.ip-toggle-point {
  display: block;
  width: 13px;
  height: 13px;
  margin: 3px 4px;
  background: #ffffff;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  position: absolute;
}

.ip-toggle-point--checked {
  left: 12px;
}
/* End - Toggle */
