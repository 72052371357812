.IronPop-salesNoti .Polaris-BlockStack {
  flex: 1;
}

.IronPop-salesNoti .content-settings .Polaris-TextField {
  height: 58px;
}

.IronPop-salesNoti .content {
  display: flex;
  flex-wrap: wrap;
}

.IronPop-salesNoti .content > .Polaris-BlockStack {
  padding: 24px 48px;
}

.IronPop-salesNoti .content > .Polaris-BlockStack .Polaris-Layout {
  max-width: 300px;
  margin-top: unset;
}

.IronPop-salesNoti .content > .Polaris-BlockStack:first-child {
  border-right: 1px solid #e1e3e5;
  border-bottom: 1px solid #e1e3e5;
  min-width: 300px;
}

.IronPop-salesNoti .properties .Polaris-Select,
.IronPop-salesNoti .properties .Polaris-TextField {
  max-width: 260px;
}
.IronPop-salesNoti .sale-noti-settings .Polaris-BlockStack--listReset {
  display: -webkit-inline-box;
}

.IronPop-salesNoti .sale-noti-settings .Polaris-BlockStack--listReset > li {
  width: 45%;
}
.IronPop-salesNoti .sale-noti-settings .url-settings-row {
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
  align-items: start;
}

.IronPop-salesNoti .sale-noti-settings .url-settings-row .Polaris-Button {
  margin-top: 6px;
}

.IronPop-salesNoti .sale-noti-settings .url-settings-row > div:first-child {
  width: 25%;
}
.IronPop-salesNoti .sale-noti-settings .url-settings-row > div:nth-child(2) {
  width: 40%;
}

/* filtering rules */

.IronPop-salesNoti .filtering-rules .section {
  display: flex;
  gap: 20px;
  padding: 0 8px;
}

.IronPop-salesNoti .filtering-rules .section:not(:last-child) {
  padding-bottom: 40px;
  border-bottom: 1px solid #e1e3e5;
}

.IronPop-salesNoti .filtering-rules .section:not(:last-child) .Polaris-Box {
  padding: 24px 48px;
}

.IronPop-salesNoti .filtering-rules .section:last-child .Polaris-Box {
  padding: 24px 0 0;
}

.IronPop-salesNoti .filtering-rules .section .heading {
  display: flex;
  justify-content: space-between;
  padding: 0 48px 24px;
}

.IronPop-salesNoti .filtering-rules .section .tag-row {
  display: flex;
  gap: 8px;
  align-items: start;
}
.IronPop-salesNoti .filtering-rules .section .tag-row > div:first-child {
  width: 50%;
}

.IronPop-salesNoti .filtering-rules .section .tag-row .Polaris-Button {
  margin-top: 6px;
}

.IronPop-salesNoti .filtering-rules .section .add {
  color: #0056d4;
  padding: 2px;
  cursor: pointer;
}

.IronPop-salesNoti .filtering-rules .section .no-product {
  border: 1px solid #d7d7d7;
  color: #aaaaaa;
  padding: 8px;
  display: flex;
  justify-content: center;
  margin: 0 48px 24px;
}

.IronPop-salesNoti
  .filtering-rules
  .section:last-child
  .IronPop-salesNoti
  .filtering-rules
  .section
  > div:first-child {
  width: 30%;
  min-width: 200px;
}

.IronPop-salesNoti .filtering-rules .section > div:last-child {
  width: 70%;
}

.IronPop-salesNoti .selected-products .item {
  border-top: 1px solid #d7d7d7;
  display: flex;
  gap: 20px;
  padding: 15px 35px;
  align-items: center;
}
.IronPop-salesNoti .selected-products .item > div:nth-child(1) {
  width: 5%;
}
.IronPop-salesNoti .selected-products .item > div:nth-child(2) {
  width: 10%;
}
.IronPop-salesNoti .selected-products .item button {
  width: 5%;
}

.IronPop-salesNoti .selected-products .item .Polaris-Icon svg,
.IronPop-salesNoti .selected-products .item .Polaris-Icon {
  width: 24px;
  height: 24px;
}
.IronPop-salesNoti .selected-products .item > div:nth-child(3) {
  max-width: 420px;
  width: 80%;
}

.IronPop-SelectProductModal .product-list .no-img .Polaris-Thumbnail {
  overflow: hidden;
}

.IronPop-SelectProductModal .product-list .no-img .Polaris-Thumbnail img {
  object-fit: cover;
  height: 100%;
}

.IronPop-salesNoti .selected-products .item img {
  width: 40px;
  height: auto;
}

.IronPop-Autocomplete {
  margin-top: 12px;
}

.IronPop-Autocomplete > span {
  margin-right: 6px;
}
