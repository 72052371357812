.IronPop-popTypes .Polaris-LegacyCard {
  max-width: 976px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
}

.IronPop-popTypes .Polaris-MediaCard > div {
  height: auto !important;
}

.Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 30% !important;
}

.Polaris-MediaCard__InfoContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 70% !important;
}

.IronPop-popTypes .info-column {
  padding: 28px 40px;
  padding-right: 20px;
}

.IronPop-popTypes .info-column .Polaris-Button {
  min-width: 94px;
}
