/* Start - Order Count */
.order-count-preview-box {
  width: 360px;
  box-shadow: 1px 1px 5px #f2f2f2;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  border: 1px solid #ffffff;
  min-height: 72px;
}

.order-count-preview-image {
  width: 40px;
  margin-left: 18px;
  margin-right: 18px;
}

.order-count-preview-first-line {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  color: #d9011a;
}
/* End - Order Count */
