.IronPop-Page-VideoLibrary .Polaris-Box {
  position: relative;
  padding: unset;
}

.IronPop-Page-VideoLibrary .Polaris-Pagination {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.IronPop-Page-VideoLibrary .Polaris-Pagination > div {
  gap: 8px;
}

.IronPop-Page-VideoLibrary .Polaris-Page {
  display: grid;
  gap: 30px;
  margin-top: 30px;
}

.IronPop-Page-VideoLibrary .IronPop-FilterBar {
  position: absolute;
  display: flex;
  gap: 5px;
  padding: 16px;
  border-bottom: var(--p-border-divider);
  right: 0;
  z-index: 20;
}

.IronPop-FiltersPopover {
  padding: 8px;
}

.IronPop-FiltersPopover .breakLine {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 8px;
  margin-top: 12px;
}

.IronPop-FiltersPopover .Polaris-Select {
  width: 168px;
}
.IronPop-Page-VideoLibrary .Polaris-Box .IronPop-Filters {
  padding: 16px;
}

.IronPop-Page-VideoLibrary .Polaris-Tabs__Outer {
  gap: 0;
}

.IronPop-Page-VideoLibrary .Polaris-Tabs__Outer > .Polaris-Box {
  padding: 16px;
  border-bottom: 1px solid #dadada;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.IronPop-library .tags-list {
  padding: 16px;
  border-bottom: 1px solid #dadada;
}

.IronPop-video-table thead .delete-row > div:first-child {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.IronPop-video-table thead .delete-row {
  display: flex;
  justify-content: space-between;
  width: 97%;
}

.IronPop-video-table thead .delete-row .actions {
  display: flex;
}

.IronPop-video-table thead .delete-row .actions .Polaris-Button {
  border: 1px solid;
  background: #ffff;
}

.IronPop-video-table .column-video .video-view {
  width: 60px;
  height: 107px;
  display: flex;
  align-items: center;
  background-color: #a9a9a9b0;
  border-radius: 4px;
}

.IronPop-video-table .column-video video {
  width: 60px;
  height: auto;
  margin: auto 0;
  border-radius: 2px;
}

.IronPop-video-table tbody tr td {
  border-bottom: 1px solid #e1e3e5;
  padding: 16px;
}
.IronPop-video-table tbody tr:not(:first-child) td {
  border-top: var(--p-border-divider);
}

.IronPop-video-table .column-video > div {
  display: flex;
  gap: 12px;
}

.IronPop-video-table .column-video > div > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.IronPop-video-table .column-video .name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 32px;
}

.IronPop-video-table .column-products > div > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.IronPop-video-table .column-products .images {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  width: 100%;
}

.IronPop-video-table .column-products .images img {
  height: 100%;
  object-fit: cover;
}

.IronPop-video-table .column-products .Polaris-Link {
  margin-top: auto;
  width: 100%;
}

.IronPop-video-table thead tr td:nth-child(2) div {
  margin-left: 10px;
}

.IronPop-video-table .Polaris-DataTable__Cell {
  vertical-align: top;
}

.IronPop-video-table .Polaris-DataTable__Cell > div {
  min-height: 100%;
}

.IronPop-video-table .Polaris-DataTable__Cell:first-child {
  padding-left: 24px !important;
}

.Polaris-DataTable__Cell {
  padding: 16px;
}

.IronPop-video-table .column-actions .actions {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.PopoverActionList-video .Polaris-Icon {
  margin: unset !important;
}

.IronPop-video-table .ip-toggle {
  width: 100%;
  justify-content: center;
}

.IronPop-NoDataTable {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 90px;
}

.IronPop-NoDataTable>div {
  text-align: center;
}

.IronPop-NoDataTable .heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

.IronPop-NoDataTable img {
  width: 80px;
  height: auto;
}

/* Add new video modal */
.IronPop-AddNewVideoModal {
  margin: -16px;
}

.IronPop-AddNewVideoModal > .Polaris-Tabs__Outer > div:nth-child(1) {
  border-bottom: 1px solid #dadada;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.Polaris-Modal-Dialog__Modal .Polaris-Box {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.IronPop-AddNewVideoModal .source-upload,
.IronPop-AddNewVideoModal .local-upload {
  padding: 26px 48px 48px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.IronPop-AddNewVideoModal .source-upload > .access-account {
  width: 500px;
}

.IronPop-AddNewVideoModal .source-upload .Polaris-Tabs__Outer > .Polaris-Box {
  padding: unset;
}

.IronPop-AddNewVideoModal .source-upload .access-account .action {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.IronPop-AddNewVideoModal .list-videos .videos {
  display: flex;
  flex-wrap: wrap;
  gap: 52px;
}

.IronPop-AddNewVideoModal .list-videos .video-item {
  width: calc(25% - 39px);
  min-width: 110px;
  display: flex;
  align-items: center;
  background-color: #b5b5b5c9;
  border-radius: 4px;
  position: relative;
  min-height: 196px;
}

.IronPop-AddNewVideoModal .list-videos .video-item video {
  border-radius: 4px;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.IronPop-AddNewVideoModal .list-videos .video-item .Polaris-Choice {
  position: absolute;
  z-index: 400;
  top: 4px;
  left: 4px;
}

.IronPop-AddNewVideoModal .source-upload .terms {
  display: flex;
  flex-direction: column;
  gap: 42px;
  padding: 0 62px;
}

.IronPop-AddNewVideoModal .source-upload .terms .action {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
}

.IronPop-AddNewVideoModal .source-upload .terms .Polaris-Choice {
  display: flex;
  align-items: start;
}

.IronPop-AddNewVideoModal
  .source-upload
  .terms
  .Polaris-Choice
  .Polaris-Choice__Control {
  margin-top: 3px;
}

.IronPop-AddNewVideoModal
  .local-upload
  .IronPop-chooseOtherFile
  .Polaris-DropZone-FileUpload
  button {
  display: none;
}

.IronPop-AddNewVideoModal .local-upload .action {
  width: 100%;
  display: flex;
  justify-content: center;
}

.IronPop-AddNewVideoModal .local-upload .item .Polaris-Icon {
  margin: unset;
}

.IronPop-AddNewVideoModal .local-upload .process-view .item > div {
  gap: 6px;
  align-items: center;
}

.IronPop-AddNewVideoModal .local-upload .process-view .item > div > div {
  width: 380px;
}

.IronPop-AddNewVideoModal .local-upload .process-view .action .Polaris-Button {
  width: 140px;
}

.IronPop-AddNewVideoModal .local-upload .success-view {
  padding: 0 122px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
