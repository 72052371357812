.IronPop-VideoModal > div {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(69, 69, 69, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.IronPop-VideoModal .modal-content {
  position: relative;
  background-color: rgb(0 0 0);
  border-radius: 8px;
  /* padding: 16px; */
  max-width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px;
}

.IronPop-VideoModal .Polaris-Button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 200;
}

.IronPop-VideoModal .Polaris-Button .Polaris-Icon {
  width: 30px;
  height: 30px;
}
